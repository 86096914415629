<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Exam types
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Exam types
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createExamType()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add exam type
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    label="Exam type"
                    v-model="search.name"
                    outlined
                    dense
                    v-on:keyup.enter="searchExamTypes()"
                    @input="search.name = $event !== null ? $event : ''"
                    clearable
                  ></v-text-field>
                </v-col>


                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                    :items="search_categories"
                    v-model="search.category_id"
                    label="Category"
                    item-text="name"
                    @change="showInstrument"
                    item-value="id"
                    v-on:keyup.enter="searchExamTypes()"
                    clearable
                    @input="search.category_id = $event !== null ? $event : ''"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                    :items="search_instruments"
                    v-model="search.instrument_id"
                    label="Instrument family"
                    item-text="name"
                    v-on:keyup.enter="searchExamTypes()"
                    item-value="id"
                    @change="showSyllabus()"
                    outlined
                    :loading="loading_instrument"
                    clearable
                    @input="search.instrument_id = $event !== null ? $event : ''"
                    dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                    :items="search_syllabuses"
                    v-model="search.syllabus_id"
                    label="Syllabus"
                    item-text="name"
                    item-value="id"
                    :loading="loading_syllabus"
                    outlined
                    v-on:keyup.enter="searchExamTypes()"
                    clearable
                    @input="search.syllabus_id = $event !== null ? $event : ''"
                    dense
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-select
                      :items="search_statuses"
                      v-model="search.status"
                      label="Status"
                      v-on:keyup.enter="searchExamTypes()"
                      item-text="name"
                      clearable
                      @input="search.status = $event !== null ? $event : ''"
                      item-value="value"
                      outlined
                      dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    @click="searchExamTypes()"
                    class="btn btn-primary w-35 float-right"
                    :loading="loading"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Exam type</th>
                  <th class="px-3">Category</th>
                  <th class="px-3">Grades</th>
                  <th class="px-3">Exam option</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <draggable
                    v-model="exam_types"
                    @change="sort"
                    @start="drag=true"
                    tag="tbody"
                    @end="drag=false"
                  >
                    <tr v-for="(item, index) in exam_types" :key="index">
                      <td class="px-3 font-weight-bolder">
                        <a @click="editExamType(item)"
                           class="text-primary font-weight-bolder text-hover-primary mb-1">
                        <i class="fa fa-sort"></i> {{ item.name }}
                        </a>
                      </td>
                      <td class="px-3">
                        <div class="text-dark-65 text-primary mb-1 font-size-lg">
                          <b class="font-weight-bolder">Category: </b> {{ item.category_name ? item.category_name : 'NA'}}
                        </div>
                        <div class="text-dark-65 text-primary mb-1 font-size-lg">
                          <b class="font-weight-bolder">Family: </b> {{ item.instrument_name ? item.instrument_name : 'NA'}}
                        </div>
                        <div class="text-dark-65 text-primary mb-1 font-size-lg">
                          <b class="font-weight-bolder"> Syllabus: </b> {{ item.syllabus_name ? item.syllabus_name : 'NA'}}
                        </div>
                      </td>
                      <td class="px-3">
                        <ul v-if="item.applicable_grade_lists">
                          <li class="font-weight-bolder text-dark-65" v-for="grade in item.applicable_grade_lists">
                            {{grade.name}}
                          </li>
                        </ul>
                        <span class="font-weight-bolder text-dark-65" v-else>
                          NA
                        </span>
                      </td>

                      <td class="px-3">
                        <span class="badge"
                          v-bind:class="{ 'badge-success': item.has_exam_options, 'badge-danger': !item.has_exam_options }"
                        >{{ item.has_exam_options ? 'Yes' : 'No' }}</span>
                      </td>

                      <td class="px-3">
                        <span class="badge"
                          v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="currentUser.access_type == 'federal'">
                                <a @click="editExamType(item)" class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteExamType(item.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="exam_types.length == 0">
                      <td class="text-center px-3" colspan="6">No items found.</td>
                    </tr>
                  </draggable>
                </template>

              </table>

              <b-pagination
                  v-if="exam_types.length > 0"
                  class="pull-right mt-7"
                  @input="getExamTypes"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                indeterminate
                color="dark"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </div>

      <create-and-update ref="create-and-update" @refresh="getExamTypes"></create-and-update>

    </div>
  </v-app>
</template>

<script>
import ExamTypeService from "@/services/product/exam-type/ExamTypeService";
import CreateAndUpdate from "./CreateAndUpdate";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import draggable from 'vuedraggable';

const exam_type = new ExamTypeService();
const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();

export default{
    components: {
        CreateAndUpdate, draggable
    },
    data(){
        return{
            total: null,
            loading: true,
            isSorting: false,
            perPage: null,
            loading_instrument: false,
            loading_syllabus: false,
            page: null,
            currentUser: {},
            exam_types: [],
            search_categories:[],
            search_instruments:[],
            search_syllabuses:[],
            search:{
                name: '',
                status: '',
                instrument_id: null,
                category_id: null,
                syllabus_id: null
            },
            search_statuses: [
                {name: 'Active', value: 1},
                {name: 'Inactive', value: 0},
            ],
        }
    },
    methods:{
        searchExamTypes(){
          this.getExamTypes();
        },
        getExamTypes(){
          this.loading = true;
          exam_type
          .paginate(this.search, this.page)
          .then(response => {
            this.exam_types=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
        },
        sort(){
          this.isSorting = true;
          exam_type
          .sort(this.exam_types)
          .then(response => {
            this.getExamTypes();
            this.isSorting = false;
            this.$snotify.success('Exam Type Sorted!!');
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
        },
        createExamType(){
            this.$refs['create-and-update'].createExamType();
        },
        editExamType(item) {
            this.$refs['create-and-update'].editExamType(item);
        },
        deleteExamType(examTypeId){
          this.$confirm({
            message: `Are you sure? `,
            button: {
              no: "No",
              yes: "Yes",
            },
            callback: (confirm) => {
              if (confirm) {
                exam_type
                .delete(examTypeId)
                .then((response) => {
                  this.getExamTypes();
                  this.$snotify.success("Exam type deleted");
                })
                .catch((err) => {
                });
              }
            },
          });
        },
        getAllCategories() {
          category
          .all()
          .then(response => {
            this.search_categories = response.data.categories;
          })
          .catch((err) => {
          });
        },
        getAllInstruments() {
          instrument
          .all()
          .then(response => {
            this.search_instruments = response.data.instruments;
          })
          .catch((err) => {
          });
        },
        getAllSyllabuses() {
          syllabus
          .all()
          .then(response => {
            this.search_syllabuses = response.data.syllabuses;
          })
          .catch((err) => {
          });
        },
        getCurrentUser() {
          this.currentUser =  this.$store.getters.currentUser;
        },
        showInstrument(){
          this.loading_instrument = true;
          if(this.search.category_id){
            category
            .getInstruments(this.search.category_id)
            .then(response => {
              this.search_syllabuses = [];
              this.search_instruments = response.data.instruments;
              this.loading_instrument = false;
            })
            .catch((err) => {
              this.loading_instrument = false;
            });
          }else{
            this.search_instruments = [];
            this.search_syllabuses = [];
            this.search.category_id = null;
            this.search.instrument_id = null;
            this.search.syllabus_id = null;
            this.loading_instrument = false;
          }
        },
        showSyllabus() {
          this.loading_syllabus = true;
          if(this.search.instrument_id){
            instrument
            .getSyllabus(this.search.instrument_id)
            .then(response => {
              this.loading_syllabus = false;
              this.search_syllabuses = response.data.syllabuses;
            })
            .catch((err) => {
              this.loading_syllabus = false;
            });
          }else{
            this.search.instrument_id = null;
            this.search.syllabus_id = null;
            this.search_syllabuses = [];
            this.loading_syllabus = false;
          }
        },
    },
    mounted(){
      this.getExamTypes();
      this.getAllCategories();
      this.getAllInstruments();
      this.getAllSyllabuses();
      this.getCurrentUser();
    }
}
</script>
