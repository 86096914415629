import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class ExamTypeService
    {
        #api = null;
        constructor() {
            this.#api = API_URL + 'admin/exam-type';
        }
        paginate(data={},index = null) {
            let url = `${this.#api}`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        all() {
            let url = `${this.#api}/get/all`;
            return apiService.get(url)
        }

        show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url)
        }
        
        create(data) {
            let url = `${this.#api}`
            return apiService.post(url, data)
        }

        update(id,data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url,data)
        }
        
        delete(examOptionId) {
            let url = `${this.#api}/${examOptionId}`
            return apiService.delete(url)
        }
        sort(data=[]){
            let url = `${this.#api}/sort`;
            return apiService.post(url, data);
        }
        getFilterData(data={}){
            let url = `${this.#api}/filter/all`;

            let param ={
                params: data
            }
            return apiService.query(url,param);
        }
        getAllExamType(data={}){
            let url = `${this.#api}/combined/all`;
            let param = {
                params: data
            }
            return apiService.query(url,param);
        }
        checkExamTypeEnabled(data={}){
            let url = `${this.#api}/check-enabled`;
            let param = {
                params: data
            }
            return apiService.query(url,param);
        }
    }
