<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} exam type</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                dense
                :error="$v.exam_type.name.$error"
                v-model="exam_type.name"
              >
                <template v-slot:label>
                  Exam type title <span class="text-danger">*</span>
                </template> 
              </v-text-field>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
              <span class="text-danger" v-if="$v.exam_type.name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="12">
              <div class="text-body">
                Description <span class="text-danger">*</span> 
              </div>
                <ckeditor
                  :config="editorConfig"
                  :error="$v.exam_type.description.$error"
                  v-model="exam_type.description" >
                </ckeditor>
                <span class="text-danger" v-if="errors.description" >{{errors.description[0]}}</span>
                <span class="text-danger" v-if="$v.exam_type.description.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12">
              <div class="text-body">Grades</div>
              <v-select item-text="name" item-value="id" :error="$v.exam_type.applicable_grades.$error"
                  v-model="exam_type.applicable_grades" :items="grades" multiple small-chips deletable-chips outlined dense>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="allSelectGrades">
                    <v-list-item-action>
                      <v-icon :color="exam_type.applicable_grades > 0 ? 'indigo darken-4' : ''">
                        {{ iconGrade }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.exam_type.applicable_grades.$error">This information is required</span>
              <span class="text-danger" v-if="errors.applicable_grades">{{ errors.applicable_grades[0] }}</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-select
                  v-model="exam_type.category_id"
                  :items="categories"
                  @change="changeCategory"
                  :error="$v.exam_type.category_id.$error"
                  outlined
                  item-text="name"
                  item-value="id"
                  dense
                  menu-props="closeOnContentClick"
              >
                <template v-slot:label>
                  Category <span class="text-danger">*</span>
                </template> 
              </v-select>
              <span class="text-danger" v-if="$v.exam_type.category_id.$error">This information is required</span>
              <span class="text-danger" v-if="errors.category_id">{{ errors.category_id[0] }}</span>
            </v-col>



            <v-col cols="12" sm="12" md="6" v-if="instrument_flag">
              <v-select
                  label="Instrument families"
                  v-model="exam_type.instrument_id"
                  :items="instruments"
                  outlined
                  @change="changeInstrument"
                  item-text="name"
                  :loading="loading_instrument"
                  item-value="id"
                  dense
                  menu-props="closeOnContentClick"
              >
              </v-select>
              <span class="text-danger" v-if="errors.instrument_id">{{ errors.instrument_id[0] }}</span>
            </v-col>

            <v-col v-if="categoryDetail && categoryDetail.has_syllabuses && syllabus_flag" cols="12" sm="12" md="6">
              <v-select @change="changeSyllabus"
                  label="Syllabus"
                  v-model="exam_type.syllabus_id"
                  :items="syllabuses"
                  outlined
                  item-text="name"
                  item-value="id"
                  :loading="loading_syllabus"
                  dense
                  menu-props="closeOnContentClick"
              >
              </v-select>
              <span class="text-danger" v-if="errors.syllabus_id">{{ errors.syllabus_id[0] }}</span>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="6" md="6" v-if="check_exam_option_enabled">
              Exam option <span class="text-danger">*</span>
              <v-switch
                  v-model="exam_type.has_exam_options"
                  :label="exam_type.has_exam_options?'Yes' : 'No'"
                  :error="$v.exam_type.has_exam_options.$error"
              ></v-switch>
              <span class="text-danger" v-if="$v.exam_type.has_exam_options.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              Status <span class="text-danger">*</span>
              <v-switch
                  v-model="exam_type.is_active"
                  :label="exam_type.is_active?'Active':'Inactive'"
                  color="primary"
              ></v-switch>
              <span class="text-danger" v-if="$v.exam_type.is_active.$error">This information is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
           text
           x-large
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
           x-large
           dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ExamTypeService from "@/services/product/exam-type/ExamTypeService";
import CategoryService from "@/services/product/category/CategoryService";
import {required, requiredIf} from "vuelidate/lib/validators";
import GradeService from "@/services/product/grade/GradeService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";

const exam_type = new ExamTypeService();
const category = new CategoryService();
const grade = new GradeService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();

export default {
  validations: {
    exam_type: {
      name:{required},
      description: {required},
      has_exam_options : {},

      category_id: {required},
      instrument_id: {},
      syllabus_id: {},
      is_active: {required},
      applicable_grades: { required},
    }
  },
  data() {
    return {
        title: "",
        edit: false,
        errors: [],
        dialog: false,
        loading: false,
        loading_instrument: false,
        loading_syllabus: false,
        categories: [],
        grades: [],
        instruments: [],
        syllabuses: [],
        exam_type: {
          name : '',
          description : '',
          has_exam_options : true,
          applicable_grades : [],
          category_id : null,
          instrument_id : null,
          syllabus_id : null,
          position : null,
          is_active : 1,
        },
        editorConfig: {
            toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
            versionCheck: false
        },
        instrument_flag: false,
        syllabus_flag: false,
        categoryDetail:null,
        instrumentDetail:null,
        syllabusDetail:null,
        check_exam_option_enabled:false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
      this.getAllGrades();
      this.getAllCategories();
    },
    createExamType() {
      this.resetForm();
      this.openDialog();
      this.edit = false;

      this.title = "Add";
    },
    editExamType(item) {
      this.openDialog();
      this.edit = true;
      this.exam_type = item;
      this.title = "Edit";
      if(item.applicable_grades == null) {
        this.exam_type.applicable_grades = []
      }
      this.changeCategory();
      this.changeInstrument();
      this.changeSyllabus();

    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    allSelectGrades() {
      this.$nextTick(() => {
        if (this.allSelectGrade) {
          this.exam_type.applicable_grades = []
        } else {
          this.exam_type.applicable_grades = [];
          this.grades.forEach(grade => {
            this.exam_type.applicable_grades.push(grade.id);
          });
        }
      })
    },
    getAllGrades() {
        grade
        .all()
        .then(response => {
        this.grades = response.data.grades;
        })
        .catch((err) => {
        });
    },
    //Category
    getAllCategories() {
      let data={};
      data={'has_exam_types':1}
      category
        .getFilterData(data)
        .then(response => {
            this.categories = response.data.categories;
        })
        .catch((err) => {

        });
    },
    changeCategory(){
      this.getCategoryDetail();

    },
    getCategoryDetail(){
      category
          .show(this.exam_type.category_id)
          .then(response => {
            this.categoryDetail=response.data.category;
            if(this.categoryDetail && this.categoryDetail.has_instruments){
              this.instrument_flag=true;
              this.getInstruments();
            }else{
              this.instrument_flag=false;
            }
            if(this.categoryDetail && !this.categoryDetail.has_instruments && this.categoryDetail.has_syllabuses){
              this.syllabus_flag=true;
              this.getSyllabuses();
            }else{
              this.syllabus_flag=false
            }
            if(this.categoryDetail && this.categoryDetail.check_exam_options_enabled){
              this.check_exam_option_enabled=true;
            }else{
              this.check_exam_option_enabled=false;
            }
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    //Instruments
    getInstruments() {
      let data={};
      if(this.exam_type.category_id){
        data['category_id']=this.exam_type.category_id;
      }
      data['has_exam_types']=1;
      instrument
          .getFilterData(data)
          .then(response => {
            this.instruments=response.data.instruments
          })
          .catch((err) => {
          });
    },
    changeInstrument() {
      this.getInstrumentDetail();
    },
    getInstrumentDetail(){
      if(this.exam_type.instrument_id){
        instrument
            .show(this.exam_type.instrument_id)
            .then(response => {
              this.instrumentDetail=response.data.instrument;
              if(this.instrumentDetail && this.instrumentDetail.has_syllabuses){
                this.syllabus_flag=true;
                this.getSyllabuses();

              }else{
                this.syllabus_flag=false;
              }
              if(this.instrumentDetail && this.instrumentDetail.check_exam_options_enabled ){
                this.check_exam_option_enabled=true;
              }else{
                this.check_exam_option_enabled=false;
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    //Syllabus
    changeSyllabus(){
      this.getSyllabusDetail();
    },
    getSyllabusDetail(){
      if(this.exam_type.syllabus_id){
        syllabus
            .show(this.exam_type.syllabus_id)
            .then(response => {
              this.syllabusDetail=response.data.syllabus;
              if(this.syllabusDetail && this.syllabusDetail.check_exam_options_enabled){
                this.check_exam_option_enabled=true;
              }else{
                this.check_exam_option_enabled=false;
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    getSyllabuses(){
      let data={};
      if(this.exam_type.category_id){
          data['category_id']=this.exam_type.category_id;
      }
      if(this.exam_type.instrument_id){
          data['instrument_id']=this.exam_type.instrument_id;
       }
      data['has_exam_types']=1;
        syllabus
          .getFilterData(data)
            .then(response => {
              this.syllabuses=response.data.syllabuses
            })
            .catch((err) => {

            });
    },
    _create: function () {
      this.loading = true;
      exam_type
      .create(this.exam_type)
      .then(response => {
        this.$snotify.success("Exam type added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      exam_type
      .update(this.exam_type.id, this.exam_type)
      .then(response => {
        this.$snotify.success("Exam type updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.exam_type = {
        name: '',
        description:null,
        applicable_grades: [],
        category_id: null,
        instrument_id: null,
        syllabus_id: null,
        exam_type: null,
        position: null,
        is_active: 1,
       has_exam_options:true,

    }, this.instrument_flag=false;
      this.syllabus_flag=false;
      this.errors=[];
      this.$v.$reset();
    },


  },
  mounted(){

  },
  computed: {
    allSelectGrade () {
      return this.exam_type.applicable_grades.length === this.grades.length
    },
    selectGrade () {
      return this.exam_type.applicable_grades.length > 0 && !this.allSelectGrade
    },
    iconGrade () {
      if (this.allSelectGrade) return 'mdi-close-box'
      if (this.selectGrade) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  }
}
</script>
